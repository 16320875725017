<template>
  <div class="has-golf-id-view" v-sgf-theme>
    <div class="has-golf-id-view__layout">
      <RadioAtom label="Jag vill registrera" group="isMinor" :options="registrationOptions" :value="isMinor" @input="setField({ field: 'isMinor', value: $event === 'true' })" mode="horizontal" />
      <RadioAtom label="Har du Golf-ID?" group="hasGolfId" :disabled="person.verificationEmailSent" :options="options" :value="person.hasGolfId" @input="onHasGolfIdInput" mode="horizontal" />
      <div v-if="person.hasGolfId" class="has-golf-id-view__layout__main-form">
        <TextInputAtom :disabled="person.verificationEmailSent" label="Golf-ID" :value="person.golfId" @input="onInput({ field: 'golfId', value: $event })" mask="######-###" width="10ch" :error="errors['golfId']" />
        <TextInputAtom :disabled="person.verificationEmailSent" label="Email" :value="person.verificationEmail" @input="onInput({ field: 'verificationEmail', value: $event })" type="email" :error="errors['verificationEmail']" />
      </div>
      <p v-if="person.hasGolfId && person.verificationEmailSent">Din verifieringskod skickas till din e-postadress</p>
      <TextInputAtom v-if="person.hasGolfId && person.verificationEmailSent" label="Verifikationskod" :value="person.verificationCode" @input="onInput({ field: 'verificationCode', value: $event })" :error="errors['verificationCode']" mask="XXXXXXXXXX" width="10ch" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import types from '../../store/types';
import RadioAtom from '../../../../widget-common/src/components/atoms/RadioAtom.vue';
import TextInputAtom from '../../../../widget-common/src/components/atoms/TextInputAtom.vue';
export default {
  components: {
    RadioAtom,
    TextInputAtom,
  },
  computed: {
    ...mapState(['persons', 'isMinor']),
    ...mapGetters({
      validation: types.getters.VALIDATION,
    }),
    personType() {
      return 'member';
    },
    person() {
      return this.persons[this.personType];
    },
    errors() {
      return this.validation
        .filter((x) => x.personType === this.personType)
        .reduce((pv, cv) => {
          pv[cv.field] = cv.value;
          return pv;
        }, {});
    },
    options() {
      return [
        { option: 'Nej', value: false },
        { option: 'Ja', value: true },
      ];
    },
    registrationOptions() {
      return [
        { option: 'Vuxen (18 år eller äldre)', value: false },
        { option: 'Underårig (17 år eller yngre)', value: true },
      ];
    },
  },
  methods: {
    ...mapActions({
      setField: types.actions.SET_FIELD,
      setPersonField: types.actions.SET_PERSON_FIELD,
    }),
    onInput(val) {
      this.setPersonField({ ...val, personType: this.personType });
    },
    onHasGolfIdInput(val) {
      this.setPersonField({ field: 'hasGolfId', value: val === 'true', personType: this.personType });
    },
  },
};
</script>

<style scoped>
.sgf-ace .has-golf-id-view__layout {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 16px;
}

.sgf-ace .has-golf-id-view__layout__main-form {
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, 1fr);
  gap: 16px;
}
</style>
