<template>
  <div class="validation-molecule" v-if="hasError || hasWarning" v-sgf-theme>
    <div class="validation-molecule__wrapper sgf-shadow" v-if="innerValidations.length === 1">
      <ValidationAtom :value="innerValidations[0].value" :type="innerValidations[0].type" :anchor="innerValidations[0].anchor" />
    </div>
    <div class="validation-molecule__wrapper validation-molecule__wrapper--expandable sgf-shadow" v-if="innerValidations.length > 1" @click="expandValidation = !expandValidation">
      <div class="validation-molecule__wrapper__header" :class="{ 'validation-molecule__wrapper__header--expanded': expandValidation }">
        <ValidationAtom :value="validationHeader.value" :type="validationHeader.type" />
        <div class="validation-molecule__wrapper__header__expand validation-molecule__wrapper__header__expand--hide" v-if="expandValidation">
          Dölj alla
          <svg>
            <use xlink:href="#arrow-down"></use>
          </svg>
        </div>
        <div class="validation-molecule__wrapper__header__expand" v-if="!expandValidation">
          Se alla
          <svg>
            <use xlink:href="#arrow-down"></use>
          </svg>
        </div>
      </div>
      <div class="validation-molecule__wrapper__content sgf-scroll" v-if="expandValidation">
        <ValidationAtom v-for="(validation, index) in innerValidations" :key="index" :value="validation.value" :type="validation.type" :anchor="validation.anchor" :number="index + 1" />
      </div>
    </div>
  </div>
</template>

<script>
import ValidationAtom from '../atoms/ValidationAtom.vue';
export default {
  components: {
    ValidationAtom,
  },
  props: {
    validations: Array,
  },
  data() {
    return {
      expandValidation: false,
    };
  },
  computed: {
    errors() {
      if (!this.validations) {
        return [];
      }
      return this.validations.filter((x) => x.type === 'error');
    },
    warnings() {
      if (!this.validations) {
        return [];
      }
      return this.validations.filter((x) => x.type === 'warning');
    },
    validationHeader() {
      if (this.hasError) {
        return {
          value: 'Du måste åtgärda ' + this.innerValidations.length + ' fel innan du kan spara.',
          type: 'error',
        };
      } else {
        return {
          value: 'Det finns ' + this.innerValidations.length + ' saker som du bör kontrollera innan du sparar.',
          type: 'warning',
        };
      }
    },
    hasError() {
      return this.errors.length > 0;
    },
    hasWarning() {
      return this.warnings.length > 0;
    },
    innerValidations() {
      if (this.hasError) {
        return this.errors;
      }
      if (this.hasWarning) {
        return this.warnings;
      }
      return [];
    },
  },
};
</script>

<style scoped>
.sgf-ace .validation-molecule {
  flex: 1;
  position: relative;
  margin-right: 36px;
  height: 36px;
  font-size: 0.8em;
}

.sgf-ace .validation-molecule__wrapper {
  background: var(--sgf-c-background);
  padding: 5px 16px;
  border-radius: 20px;
  line-height: 26px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.sgf-ace .validation-molecule__wrapper--expandable {
  cursor: pointer;
}

.sgf-ace .validation-molecule__wrapper__header {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, max-content);
  gap: var(--ace-gutter);
}

.sgf-ace .validation-molecule__wrapper__header--expanded {
  border-bottom: 1px solid var(--sgf-c-secondary-gray);
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.sgf-ace .validation-molecule__wrapper__header__expand {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.validation-molecule__wrapper__header__expand svg {
  width: 16px;
  height: 16px;
}

.sgf-ace .validation-molecule__wrapper__header__expand--hide svg {
  transform: rotate(180deg);
}

.sgf-ace .validation-molecule__wrapper__content {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
