import Vue from 'vue';
import App from './App.vue';
import themeDirective from '../../widget-common/src/directives/themeDirective';
import store from './store';

function sgf_wmpa_initialize(config) {
  if (!config) {
    return;
  }
  Vue.prototype.$widgetConfiguration = config;

  Vue.directive('sgf-theme', {
    ...themeDirective,
    config: config,
  });

  let element;
  if (config.element) {
    element = config.element;
  } else {
    element = document.getElementById(config.hook);
  }

  element.innerHTML = '';
  const app = new Vue({
    store,
    render: (h) => h(App),
  });
  app.$mount(element);
}

window.sgf_wmpa_initialize = sgf_wmpa_initialize;

Vue.config.productionTip = false;
sgf_wmpa_initialize(window['sgf_wmap'] ? window['sgf_wmap'].config : undefined);
