<template>
  <div class="new-member-view" v-sgf-theme>
    <div class="new-member-view__layout" :style="layoutStyle" v-if="isCaregiver">
      <RadioAtom label="Har du Golf-ID?" :group="personType + 'hasGolfId'" :options="options" :value="person.hasGolfId" @input="onHasGolfIdInput" mode="horizontal" />
    </div>
    <div class="new-member-view__layout" :style="layoutStyle" v-if="isCaregiver && hasGolfId">
      <TextInputAtom label="Golf-ID" :value="person.golfId" @input="setField({ field: 'golfId', value: $event })" mask="######-###" width="10ch" :error="errors['golfId']" />
    </div>
    <div v-if="!hasGolfId">
      <TextInputAtom label="Personnummer" :value="person.ssn" @input="setField({ field: 'ssn', value: $event })" mask="########-####" width="13ch" :error="errors['ssn']" />
    </div>
    <div class="new-member-view__layout" :style="layoutStyle" v-if="!hasGolfId">
      <TextInputAtom label="Förnamn" :value="person.firstName" @input="setField({ field: 'firstName', value: $event })" :error="errors['firstName']" />
      <TextInputAtom label="Efternamn" :value="person.lastName" @input="setField({ field: 'lastName', value: $event })" :error="errors['lastName']" />
      <TextInputAtom label="Gatuadress" :value="person.address" @input="setField({ field: 'address', value: $event })" :error="errors['address']" />
      <TextInputAtom label="Postnummer" :value="person.postalCode" @input="setField({ field: 'postalCode', value: $event })" mask="### ##" width="6ch" :error="errors['postalCode']" />
      <TextInputAtom label="Postort" :value="person.postalAddress" @input="setField({ field: 'postalAddress', value: $event })" :error="errors['postalAddress']" />
    </div>
    <div class="new-member-view__layout" :style="layoutStyle" v-if="!hasGolfId">
      <TextInputAtom label="Telefon" :value="person.mobile" @input="setField({ field: 'mobile', value: $event })" type="tel" :error="errors['mobile']" />
      <div></div>
      <TextInputAtom label="E-post" :value="person.email" @input="setField({ field: 'email', value: $event })" type="email" :error="errors['email']" />
      <TextInputAtom label="Bekräfta E-post" :value="person.confirmEmail" @input="setField({ field: 'confirmEmail', value: $event })" type="email" :error="errors['confirmEmail']" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import types from '../../store/types';
import TextInputAtom from '../../../../widget-common/src/components/atoms/TextInputAtom.vue';
import RadioAtom from '../../../../widget-common/src/components/atoms/RadioAtom.vue';
export default {
  components: {
    TextInputAtom,
    RadioAtom,
  },
  props: {
    personType: String,
  },
  computed: {
    ...mapState(['persons', 'memberships']),
    ...mapGetters({
      validation: types.getters.VALIDATION,
    }),
    isCaregiver() {
      return this.personType !== 'member';
    },
    person() {
      return this.persons[this.personType];
    },
    hasGolfId() {
      return this.person.hasGolfId;
    },
    errors() {
      return this.validation
        .filter((x) => x.personType === this.personType)
        .reduce((pv, cv) => {
          pv[cv.field] = cv.value;
          return pv;
        }, {});
    },
    layoutStyle() {
      let result = {};
      let { theme, columns } = this.$widgetConfiguration;
      if (theme === 'ace' && columns) {
        result['grid-template-columns'] = `repeat(${columns}, minmax(0, 1fr))`;
      }
      return result;
    },
    options() {
      return [
        { option: 'Nej', value: false },
        { option: 'Ja', value: true },
      ];
    },
  },
  methods: {
    ...mapActions({
      setPersonField: types.actions.SET_PERSON_FIELD,
    }),
    setField(field) {
      this.setPersonField({ ...field, personType: this.personType });
    },
    onHasGolfIdInput(val) {
      this.setPersonField({ field: 'hasGolfId', value: val === 'true', personType: this.personType });
    },
  },
};
</script>

<style scoped>
.sgf-ace.new-member-view {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 16px;
}
.sgf-ace .new-member-view__layout {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 16px;
}
</style>
