<template>
  <div class="api-atom" v-sgf-theme>
    <slot></slot>
    <div class="api-atom__overlay" v-if="isLoading || error"></div>
    <div class="api-atom__loader" v-if="isLoading">
      <svg class="api-atom__loader__icon">
        <use xlink:href="#loader-primary"></use>
      </svg>
      <div class="api-atom__loader__header">{{ header }}</div>
      <div class="api-atom__loader__sub">{{ sub }}</div>
    </div>
    <div class="api-atom__error" v-if="error">
      <svg class="api-atom__error__cross" @click="$emit('dismissError')">
        <use xlink:href="#cross"></use>
      </svg>
      <div class="api-atom__error__layout">
        <svg class="api-atom__error__layout__icon">
          <use xlink:href="#info"></use>
        </svg>
        <div>
          <div class="api-atom__error__layout__header">Obs!</div>
          <div class="api-atom__error__layout__text">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: 'Laddar,',
    },
    sub: {
      type: String,
      default: 'det kan ta några sekunder...',
    },
    isLoading: Boolean,
    error: String,
  },
};
</script>

<style scoped>
.sgf-ace.api-atom {
  position: relative;
}

.sgf-ace .api-atom__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--sgf-c-background);
  opacity: 0.7;
}

.sgf-ace .api-atom__loader {
  position: absolute;
  background: var(--sgf-c-background);
  color: var(--sgf-c-text);
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 50px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
}

.sgf-ace .api-atom__loader__icon {
  width: 70px;
  height: 70px;
  -webkit-animation: sgf-rotating 2s linear infinite;
  -moz-animation: sgf-rotating 2s linear infinite;
  -ms-animation: sgf-rotating 2s linear infinite;
  -o-animation: sgf-rotating 2s linear infinite;
  animation: sgf-rotating 2s linear infinite;
}
.sgf-ace .api-atom__loader__header {
  font-weight: bold;
}

.sgf-ace .api-atom__error {
  position: absolute;
  background: var(--sgf-c-background);
  color: var(--sgf-c-text);
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
  color: var(--sgf-c-status-red);
  --sgf-c-fill: var(--sgf-c-status-pink);
  text-align: right;
}

.sgf-ace .api-atom__error__layout__header {
  line-height: 25px;
  font-size: 1.25em;
  font-weight: bold;
}

.sgf-ace .api-atom__error__cross {
  display: block;
  width: 16px;
  height: 16px;
  padding: 8px;
  margin-left: auto;
  color: var(--sgf-c-text);
  cursor: pointer;
}

.sgf-ace .api-atom__error__layout {
  padding: 0 25px 8px 25px;
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, 1fr);
  gap: 8px;
  text-align: left;
}

.sgf-ace .api-atom__error__layout__icon {
  width: 25px;
  height: 25px;
}

@-webkit-keyframes sgf-rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes sgf-rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
