import Vue from 'vue';
import types from './types';
export default {
  [types.mutations.SET_FIELD]: (state, fieldValue) => {
    Vue.set(state, fieldValue.field, fieldValue.value);
  },
  [types.mutations.SET_PERSON_FIELD]: (state, fieldValue) => {
    let { field, value, personType } = fieldValue;
    Vue.set(state.persons[personType], field, value);
  },
  [types.mutations.CLEANUP]: (state) => {
    Vue.set(state, 'hasSubmitted', false);
    Vue.set(state, 'registered', false);
    Vue.set(state, 'recaptchaToken', undefined);
  },
  [types.mutations.CLEANUP_PERSON]: (state, person) => {
    Vue.set(state.persons[person], 'ssn', '');
    Vue.set(state.persons[person], 'firstName', '');
    Vue.set(state.persons[person], 'lastName', '');
    Vue.set(state.persons[person], 'address', '');
    Vue.set(state.persons[person], 'postalCode', '');
    Vue.set(state.persons[person], 'postalAddress', '');
    Vue.set(state.persons[person], 'phone', '');
    Vue.set(state.persons[person], 'mobile', '');
    Vue.set(state.persons[person], 'email', '');
    Vue.set(state.persons[person], 'confirmEmail', '');
    Vue.set(state.persons[person], 'golfId', '');
    Vue.set(state.persons[person], 'verificationEmail', '');
    Vue.set(state.persons[person], 'verificationEmailSent', false);
    Vue.set(state.persons[person], 'verificationCode', '');
  },
};
