<template>
  <button class="button-atom" :class="classes" :disabled="disabled" type="button" v-sgf-theme @click="$emit('click')">{{ label }}</button>
</template>

<script>
export default {
  props: {
    label: String,
    disabled: Boolean,
    type: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    classes() {
      return {
        'button-atom--disabled': this.disabled,
        'button-atom--secondary': this.type === 'secondary',
        'sgf-ace': this.$widgetConfiguration.theme === 'ace',
      };
    },
  },
};
</script>

<style scoped>
.sgf-ace.button-atom {
  display: inline-block;
  outline: none;
  border: 3px solid white;
  padding: 3px 24px;
  cursor: pointer;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  color: var(--sgf-c-text);
  font-size: 16px;
  line-height: 24px;
  background-color: var(--sgf-c-primary);
  font-weight: bold;
}
.sgf-ace.button-atom--secondary {
  background-color: var(--sgf-c-secondary);
  font-weight: normal;
}
.sgf-ace.button-atom:not(:active):not(:focus):hover {
  background: var(--sgf-c-secondary);
  box-shadow: inset 0 0 0 1px var(--sgf-c-text), 0px 3px 6px #00000029;
}
.sgf-ace.button-atom:not(:active):focus {
  background: var(--sgf-c-secondary);
  box-shadow: 0 0 0 2px var(--sgf-c-text), 0px 3px 6px #00000029;
}
.sgf-ace.button-atom:not(.button-atom--secondary):active {
  background-color: var(--sgf-c-text);
  box-shadow: inset 0 0 0 1px var(--sgf-c-text), 0px 3px 6px #00000029;
  color: white;
}
.sgf-ace.button-atom--disabled {
  cursor: auto;
  background: var(--sgf-c-secondary-gray);
  color: var(--sgf-c-primary-gray);
  border: 3px solid #d6d6d6;
  pointer-events: none;
}
</style>
