import { render, staticRenderFns } from "./ValidationMolecule.vue?vue&type=template&id=233af20a&scoped=true&"
import script from "./ValidationMolecule.vue?vue&type=script&lang=js&"
export * from "./ValidationMolecule.vue?vue&type=script&lang=js&"
import style0 from "./ValidationMolecule.vue?vue&type=style&index=0&id=233af20a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../widget-membership-application-app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "233af20a",
  null
  
)

export default component.exports